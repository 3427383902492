/* eslint-disable consistent-return */
import moment from "moment";
import { SHOPPING_CART_VALID_DAYS } from "../../../settings";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";
import Delay from "../../../utils/delay/delay";
import { convertProductOrRecordToCartProduct } from "../../../utils/mapper/mapper";
import { CartTemplateEvents } from "../../../analytics/cartTemplateEvents";
import { fetchProductById } from "../index";
import { clampProductCount } from "../../../utils/cartUtil/cartUtil";

// types
export const Cart = Object.freeze({
  UPDATE_CART: "UPDATE_CART",
  CREATE: "CART_CREATE",
  FETCH_STARTED: "CART_FETCH_STARTED",
  FETCH_FINISHED: "CART_FETCH_FINISHED",
  FETCH_ERROR: "CART_FETCH_ERROR",
  SEND_STARTED: "CART_SEND_STARTED",
  SEND_FINISHED: "CART_SEND_FINISHED",
  SEND_ERROR: "CART_SEND_ERROR",
  DELETE_CART: "DELETE_CART",
  CART_INITIALIZED: "CART_INITIALIZED",
  SET_STATE: "CART_SET_STATE",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
  CART_UPDATE_ERROR: "CART_UPDATE_ERROR",
});

export const CartState = Object.freeze({
  ADD_NEW: "ADD_NEW",
  ADD_NEW_TEMPLATE: "ADD_NEW_TEMPLATE",
  SHOW_CONTENT: "SHOW_CONTENT",
  SHOW_TEMPLATES: "SHOW_TEMPLATES",
});

// -- ACTIONS --

const HTTP_STATUS_GONE = 410;
const HTTP_STATUS_NOT_FOUND = 404;
const HTTP_STATUS_CONFLICT = 409;
const delay = new Delay();
const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);

export const cartInit = userId => async dispatch => {
  const path = `${baseUrl}cart/v2/owners/${userId}/carts`;
  try {
    // start
    dispatch({ type: Cart.FETCH_STARTED });

    const carts = await RestService.get(path);

    // update
    dispatch({
      type: Cart.FETCH_FINISHED,
      payload: carts,
    });

    // initialize
    dispatch({
      type: Cart.CART_INITIALIZED,
    });

    return carts;
  } catch (error) {
    // error handling
    dispatch({ type: Cart.FETCH_ERROR, payload: error });
  }
};

const doSendAsync = async (dispatch, userId, cart) => {
  const path = `${baseUrl}cart/v2/owners/${userId}/carts/${cart.cartId}`;

  try {
    // start
    dispatch({ type: Cart.SEND_STARTED });

    const updatedCart = await RestService.put(path, cart);

    // finished
    dispatch({ type: Cart.SEND_FINISHED });

    return updatedCart;
  } catch (error) {
    // user is trying to save cart that is already expired or deleted
    if (
      error.status === HTTP_STATUS_GONE ||
      error.status === HTTP_STATUS_NOT_FOUND ||
      error.status === HTTP_STATUS_CONFLICT
    ) {
      // re-fetch carts
      dispatch(cartInit(userId));
    }
    // error handling
    dispatch({ type: Cart.SEND_ERROR, payload: error });
  }
};

const doSendAsyncAddProd = async (dispatch, userId, cartId, product) => {
  const path = `${baseUrl}cart/v2/owners/${userId}/carts/${cartId}`;
  try {
    // start
    dispatch({ type: Cart.SEND_STARTED });

    const updatedCart = await RestService.patch(path, product);

    // finished
    dispatch({ type: Cart.SEND_FINISHED });

    return updatedCart;
  } catch (error) {
    // user is trying to save cart that is already expired or deleted
    if (
      error.status === HTTP_STATUS_GONE ||
      error.status === HTTP_STATUS_NOT_FOUND
    ) {
      // re-fetch carts
      dispatch(cartInit(userId));
    }
    // error handling
    dispatch({ type: Cart.SEND_ERROR, payload: error });
  }
};

export const setCartState = cartState => dispatch =>
  dispatch({ type: Cart.SET_STATE, payload: cartState });

export const cartCreate = (userId, cart) => async (dispatch, getState) => {
  if (getState().cart.sendingCart) {
    return;
  }
  const path = `${baseUrl}cart/v2/owners/${userId}/carts`;

  try {
    // start
    dispatch({ type: Cart.SEND_STARTED });

    const createdCart = await RestService.post(path, cart);

    // finished
    dispatch({ type: Cart.SEND_FINISHED });

    // update
    dispatch({
      type: Cart.CREATE,
      payload: { cart: createdCart, analytics: { cart: createdCart } },
    });

    return createdCart;
  } catch (error) {
    // error handling
    dispatch({ type: Cart.SEND_ERROR, payload: error });
  }
};

const getTargetCart = (carts, cartId) => {
  const cartsCopy = carts.slice(0);
  const index = cartsCopy.map(cart => cart.cartId).indexOf(cartId);
  return cartsCopy[index];
};

export const cartSetNote =
  (userId, cartId, note) => async (dispatch, getState) => {
    // update note
    const cart = getTargetCart(getState().cart.carts, cartId);
    cart.note = note;

    const updatedCart = await doSendAsync(dispatch, userId, cart);

    if (updatedCart) {
      // update to local state
      dispatch({
        type: Cart.UPDATE_CART,
        payload: {
          cart: updatedCart,
          analytics: {
            updateType: CartTemplateEvents.SetNote,
            cart: updatedCart,
          },
        },
      });
    }
    return updatedCart;
  };

export const cartSetCustomerAndShippingAddress =
  (userId, cartId, customerId, shippingAddressId) =>
  async (dispatch, getState) => {
    // update note
    const cart = getTargetCart(getState().cart.carts, cartId);
    cart.customer = customerId;
    cart.shipToAddress = shippingAddressId;

    const updatedCart = await doSendAsync(dispatch, userId, cart);

    if (updatedCart) {
      // update to local state
      dispatch({
        type: Cart.UPDATE_CART,
        payload: {
          cart: updatedCart,
          analytics: {
            updateType: CartTemplateEvents.SetCustomerAndShippingAddress,
            cart: updatedCart,
          },
        },
      });
    }

    // update
    return updatedCart;
  };

const doUpdateCartProductCount = async (
  dispatch,
  cartId,
  carts,
  product,
  count,
  resetCount,
  getState
) => {
  const index = carts.map(cart => cart.cartId).indexOf(cartId);
  const cart = carts[index];

  // update updatedDate when cart is modified
  cart.updatedDate = moment().toISOString();

  // check if there's already this product
  const productIndex = cart.products
    .map(cartProduct => cartProduct.product.materialId)
    .indexOf(product.materialId);

  if (productIndex !== -1) {
    // update count
    if (resetCount) {
      cart.products[productIndex].count = count;
    } else {
      cart.products[productIndex].count = clampProductCount(
        cart.products[productIndex].count + count
      );
    }
    return cart;
  }
  // Fetch full product information if record.
  // This is required for storing additional product attributes (e.x. storage temperature) to shopping cart.
  if (Array.isArray(product.localizedName)) {
    product = await dispatch(fetchProductById(product.materialId));
  }
  const productError = getState().product?.productFetchByIdError;
  if (!productError) {
    // convert to cart product.
    const cartProduct = convertProductOrRecordToCartProduct(product);
    // add
    cart.products.push({
      product: cartProduct,
      count,
    });
    return cart;
  }
  dispatch({ type: Cart.CART_UPDATE_ERROR, payload: productError });
};
export const cartSetProductCount = (userId, cartId, product, count) => {
  count = clampProductCount(count);
  return async (dispatch, getState) => {
    const carts = getState().cart.carts.slice(0);
    const cart = await doUpdateCartProductCount(
      dispatch,
      cartId,
      carts,
      product,
      count,
      true,
      getState
    );

    if (cart) {
      // delay send (to handle heavy mouse clickers)
      // Send entire cart to server
      const updatedCart = await delay.run(
        () => doSendAsync(dispatch, userId, cart),
        1000
      );

      if (updatedCart) {
        // update to local state based on server response
        dispatch({
          type: Cart.UPDATE_CART,
          payload: {
            cart: updatedCart,
            analytics: {
              updateType: CartTemplateEvents.SetProductCount,
              cart: updatedCart,
              product,
              count,
            },
          },
        });
      }
    }
  };
};

export const cartAddProduct =
  (userId, cartId, product, count) => async (dispatch, getState) => {
    // Fetch full product information if record.
    // This is required for storing additional product attributes (e.x. storage temperature) to shopping cart.
    if (Array.isArray(product.localizedName)) {
      product = await dispatch(fetchProductById(product.materialId));
    }
    const productError = getState().product?.productFetchByIdError;
    if (!productError) {
      // convert to cart product.
      const cartProduct = convertProductOrRecordToCartProduct(product);
      if (cartProduct) {
        const body = { count, product: cartProduct };
        const updatedCart = await doSendAsyncAddProd(
          dispatch,
          userId,
          cartId,
          body
        );

        if (updatedCart) {
          // update to local state based on server response
          dispatch({
            type: Cart.UPDATE_CART,
            payload: {
              cart: updatedCart,
              analytics: {
                updateType: CartTemplateEvents.AddProduct,
                cart: updatedCart,
                product,
                count,
              },
            },
          });
        }
        return updatedCart;
      }
    }
    dispatch({ type: Cart.CART_UPDATE_ERROR, payload: productError });
  };

export const cartRemoveProduct =
  (userId, cartId, productId) => async (dispatch, getState) => {
    // update products
    const carts = getState().cart.carts.slice(0);
    const index = carts.map(cart => cart.cartId).indexOf(cartId);
    const cart = carts[index];

    // update updatedDate when cart is modified
    cart.updatedDate = moment().toISOString();

    // check if there's already this product

    const productIndex = cart.products
      .map(cartProduct => cartProduct.product.materialId)
      .indexOf(productId);

    // for analytics
    const cartProduct = cart.products[productIndex];

    // product found
    cart.products.splice(productIndex, 1);

    const updatedCart = await delay.run(
      () => doSendAsync(dispatch, userId, cart),
      1000
    );

    if (updatedCart) {
      dispatch({
        type: Cart.UPDATE_CART,
        payload: {
          cart: updatedCart,
          analytics: {
            updateType: CartTemplateEvents.RemoveProduct,
            cart: updatedCart,
            product: cartProduct.product,
          },
        },
      });
    }
  };

// this function will clear all errors from all reducers, not just from cart one ! !
export const clearAllErrors = () => dispatch => {
  dispatch({ type: Cart.CLEAR_ALL_ERRORS, payload: null });
};

export const cartDelete = (userId, cart) => async dispatch => {
  const path = `${baseUrl}cart/v2/owners/${userId}/carts/${cart.cartId}`;
  try {
    // start
    dispatch({ type: Cart.SEND_STARTED });

    await RestService.delete(path);

    // finished
    dispatch({ type: Cart.SEND_FINISHED });

    // update
    dispatch({
      type: Cart.DELETE_CART,
      payload: { cart, analytics: { cart } },
    });

    return cart;
  } catch (error) {
    // error handling
    dispatch({ type: Cart.SEND_ERROR, payload: error });
  }
};

export const cartAddFromTemplate =
  (userId, template, cart, isNewCart = false) =>
  async dispatch => {
    const cartProducts = [...cart.products];
    const templateProducts = [...template.products];
    // Add count to existing products on cart
    const updatedProducts = cartProducts.map(product => {
      const existingProduct = templateProducts.find(
        x => x.product.materialId === product.product.materialId
      );
      if (existingProduct) {
        return { ...product, count: product.count + existingProduct.count };
      }
      return product;
    });

    // We must filter out items from templateProducts which were added as updated item above to avoid duplicates
    // -> construct a list of materialIds which are already in updatedProducts
    const updatedMaterialIds = updatedProducts.map(
      updatedProduct => updatedProduct.product.materialId
    );

    // .. filter out materialIds which are in updatedProducts
    const filteredTemplateProducts = templateProducts.filter(
      templateProduct =>
        !updatedMaterialIds.includes(templateProduct.product.materialId)
    );

    const updatedCart = {
      ...cart,
      // Merge template products and updated cart products so there won't be duplicate products
      products: updatedProducts.concat(filteredTemplateProducts),
      // Copy customer data from template for new carts only
      customer: isNewCart ? template.customer : cart.customer,
      shipToAddress: isNewCart ? template.shipToAddress : cart.shipToAddress,
    };

    const result = await doSendAsync(dispatch, userId, updatedCart);
    if (result) {
      // update to local state on success
      dispatch({
        type: Cart.UPDATE_CART,
        payload: {
          cart: result,
          analytics: {
            updateType: CartTemplateEvents.CreateFromTemplate,
            cart: updatedCart,
          },
        },
      });
      return result;
    }
  };

// -- REDUCER --

const INIT_STATE = {
  carts: [],
  cartState: CartState.SHOW_CONTENT,
  fetchingCart: false,
  cartFetchError: null,
  sendingCart: false,
  cartSendError: null,
  initialized: false,
  cartUpdateError: null,
};
// eslint-disable-next-line
export const cartReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Cart.CREATE: {
      const carts = state.carts.slice(0);
      const { cart } = action.payload;
      carts.push(cart);
      return { ...state, carts, cartState: CartState.SHOW_CONTENT };
    }
    case Cart.CLEAR_ALL_ERRORS: {
      return { ...state, cartSendError: null, cartFetchError: null };
    }
    case Cart.UPDATE_CART: {
      const { cart } = action.payload;
      // this should be done in the server side?
      // update expiration date always when modifying
      cart.expirationDate = moment().add(SHOPPING_CART_VALID_DAYS, "days");
      const carts = state.carts.slice(0);
      const index = carts.map(tempCart => tempCart.cartId).indexOf(cart.cartId);
      carts[index] = cart;
      // update
      state.cartUpdateError = null;
      return { ...state, carts };
    }
    case Cart.CART_UPDATE_ERROR:
      return { ...state, cartUpdateError: action.payload };
    case Cart.FETCH_STARTED: {
      return { ...state, fetchingCart: true, cartFetchError: null };
    }
    case Cart.FETCH_FINISHED: {
      const carts = action.payload;
      return { ...state, fetchingCart: false, carts };
    }
    case Cart.FETCH_ERROR:
      return { ...state, fetchingCart: false, cartFetchError: action.payload };
    case Cart.SEND_STARTED:
      return { ...state, sendingCart: true, cartSendError: null };
    case Cart.SEND_FINISHED:
      return { ...state, sendingCart: false };
    case Cart.SEND_ERROR:
      return { ...state, sendingCart: false, cartSendError: action.payload };
    case Cart.DELETE_CART: {
      const { cart } = action.payload;
      const carts = state.carts.filter(x => x.cartId !== cart.cartId);
      return { ...state, carts };
    }
    case Cart.CART_INITIALIZED:
      return { ...state, initialized: true };
    case Cart.SET_STATE: {
      const newState = CartState[action.payload] || CartState.SHOW_CONTENT;
      return { ...state, cartState: newState };
    }
    default:
      return state;
  }
};
